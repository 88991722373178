<template>
  <b-modal ref="modal" :ok-disabled="!canSubmit" @ok="confirmProductOrder">
    <template #modal-title>
      {{ $t('product-order-confirm.prompt.title') }}
    </template>
    <template #modal-ok>
      <b-spinner v-if="loading" small></b-spinner>
      {{ $t('product-order-confirm.action') }}
    </template>
    <template #modal-cancel>{{ $t('cancel') }}</template>

    <p>{{ $t('product-order-confirm.prompt.content') }}</p>
    <ul>
      <li
        v-for="product in productOrder.items"
        :key="product.id"
        :title="product.name"
      >
        <strong>{{ product.quantity }} x {{ product.name }}</strong>
        <b-form inline v-if="product.requireSerialNumber">
          <label class="mr-sm-2">
            {{ $t('product-order-confirm.prompt.serial') }}:
          </label>
          <b-input v-model="product.serialNumber" required />
        </b-form>
      </li>
    </ul>
    <b-alert v-if="error" show variant="danger">
      {{ error }}
    </b-alert>
  </b-modal>
</template>
<script>
import axios from 'axios';
import { CARRIER_UNKNOWN_ID } from '@gid/models';
import { UserRoleEnum } from '@gid/models';
import { CommentActionTypeEnum } from '@gid/models/dist/entities/comment';
export default {
  props: {
    jobId: String,
  },
  data() {
    return {
      productOrder: {
        id: null,
        items: [],
      },
      loading: true,
      error: null,
    };
  },
  computed: {
    canSubmit() {
      return (
        !this.loading &&
        this.productOrder.items.every(
          (item) => !item.requireSerialNumber || item.serialNumber,
        )
      );
    },
  },
  methods: {
    show(productOrder) {
      this.productOrder = productOrder;
      this.checkProductsRequiringSerialNumber();
      this.$refs.modal.show();
      this.error = null;
    },
    async checkProductsRequiringSerialNumber() {
      this.loading = true;
      const { data: productIds } = await axios.get(
        `/documents-api/product-orders/${this.productOrder.id}/requiring-serial-number`,
      );
      productIds.forEach((productId) => {
        const item = this.productOrder.items.find(({ id }) => id == productId);
        this.$set(item, 'serialNumber', item.serial_number);
        this.$set(item, 'requireSerialNumber', true);
      });
      this.loading = false;
    },
    async confirmProductOrder(event) {
      event.preventDefault();
      this.loading = true;
      try {
        await axios.patch(
          `/documents-api/product-orders/${this.productOrder.id}`,
          {
            trackingID: 'customer-confirmation-received-products',
            carrier: { id: CARRIER_UNKNOWN_ID },
            comment: {
              message:
                `Der Kunde hat den Empfang der Produkte<br/>` +
                this.productOrder.items
                  .map((product) => `<strong>${product.name}</strong><br/>`)
                  .join('') +
                `bestätigt.`,
              visibleForRoles: [
                UserRoleEnum.ADMIN,
                UserRoleEnum.BRAND,
                UserRoleEnum.PARTNER,
                UserRoleEnum.CUSTOMER,
              ],
              includeInProtocol: false,
              actionType: CommentActionTypeEnum.GENERIC,
            },
            serials: this.productOrder.items
              .filter(({ serialNumber }) => serialNumber)
              .map(({ id, serialNumber }) => ({
                productId: id,
                serialNumber,
              })),
          },
        );
        this.$emit('confirmed');
        this.$refs.modal.hide();
      } catch (error) {
        this.error = error.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>
